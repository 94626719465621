import React from 'react'
import "./Soutez_block.css"
import skutr from "../imgs/ceny/skutr_cover.png";
import kolobezka from "../imgs/ceny/kolobezka.jpg";
import darkovy_poukaz from "../imgs/ceny/darkovy_poukaz.jpg";
import zivot_jako_hra from "../imgs/ceny/zivot_jako_hra.png";
import first_class from "../imgs/ceny/first_class.jpg";
import fc from "../imgs/ceny/fc.png";
import code_of_life from "../imgs/ceny/code_of_life.jpg";
import col from "../imgs/ceny/col.png";
import megapecka from "../imgs/ceny/megapecka.png";
import opasek from "../imgs/ceny/opasek.jpg";
import tshock from "../imgs/ceny/tshock.jpg";
import tshock_logo from "../imgs/ceny/tshock_logo.png";
import tricko from "../imgs/ceny/tricko.jpg";


import ec_ponozky from "../imgs/ceny/ec_ponozky.jpg";
import myjomi from "../imgs/ceny/myjomi.png";
import ponozky from "../imgs/ceny/ponozky.jpg";
import uam from "../imgs/ceny/uam.png";
import TW from "../imgs/ceny/TW.png";
import FUTRALOVNA from "../imgs/loga_resize/futralovna.png";
import GG from "../imgs/loga_resize/GG.png";

import rekonstrukce from "../imgs/ceny/rekonstrukce.jpg";
import IAP from "../imgs/ceny/IAP.jpg";
import IAP_logo from "../imgs/ceny/IAP_logo.png";
import pruvodce_sebepoznanim from "../imgs/ceny/pruvodce_sebepoznanim.jpg";
import rimoto_logo from "../imgs/ceny/rimoto_logo.png";
import skutr_2 from "../imgs/ceny/skutr_2.png";
import kolobezka_konfety from "../imgs/ceny/kolobezka_konfety.jpg";
import darkovy_poukaz_konfety from "../imgs/ceny/darkovy_poukaz_konfety.jpg";
import first_class_konfety from "../imgs/ceny/first_class_konfety.jpg";
import code_of_life_konfety from "../imgs/ceny/code_of_life_konfety.jpg";
import opasek_konfety from "../imgs/ceny/opasek_konfety.jpg";
import tshock_konfety from "../imgs/ceny/tshock_konfety.jpg";
import tricko_konfety from "../imgs/ceny/tricko_konfety.jpg";
import ec_ponozky_konfety from "../imgs/ceny/ec_ponozky_konfety.jpg";
import ponozky_konfety from "../imgs/ceny/ponozky_konfety.jpg";
import rekonstrukce_konfety from "../imgs/ceny/rekonstrukce_konfety.jpg";
import pruvodce_sebepoznanim_konfety from "../imgs/ceny/pruvodce_sebepoznanim_konfety.jpg";
import IAP_konfety from "../imgs/ceny/IAP_konfety.jpg";
import satky from "../imgs/ceny/satky.png";
import satky_konfety from "../imgs/ceny/satky_loga.png";
import ponozkarna_logo from "../imgs/ceny/ponozkarna_logo.png";
import ipad from "../imgs/ceny/ipad.png";
import ipad_konfety from "../imgs/ceny/ipad_konfety.png";
import mince_konfety from "../imgs/ceny/mince_konfety.png";
import mince from "../imgs/ceny/mince.png";
import airpods from "../imgs/ceny/airpods.png";
import ipods_konfety from "../imgs/ceny/ipods_konfety.png";
import kryty from "../imgs/ceny/kryty.png";
import kryty_konfety from "../imgs/ceny/kryty_konfety.png";
import {Link} from "react-router-dom"

const ceny = [
  {
    photo: IAP,
    count: 10000,
    name: "Komplexní karierní diagnostika",
    desp:
      "Karierní diagnostika poskytuje pohled na vaše schopnosti a preference.",
    logo: IAP_logo,
    background: IAP_konfety,
    design: "on"
  },
  {
    photo: pruvodce_sebepoznanim,
    count: 10000,
    name: "E-kniha Průvodce sebepoznáním",
    desp:
      "Kniha je průvodcem sebepoznání člověka jdoucí do nejhlubších vrstev osobnosti.",
    logo: IAP_logo,
    background: pruvodce_sebepoznanim_konfety,
    design: "on"

  },
  
    {
      photo: skutr,
      count: 1,
      name: "Skútr Motorro Digita 50 4T + helma",
      desp: "Motor čtyřtaktní jednoválec, vzduchem chlazený Objem 50 ccm.",
      logo: rimoto_logo,
      background: skutr_2,
    },
    {
      photo: kolobezka,
      count: 1,
      name: "Ekoloběžka Salamander + helma",
      desp:
        "Koloběžka nabitá energií, pohodlím, silnými brzdami a cool desingem.",
      logo: rimoto_logo,
      background: kolobezka_konfety,
  },
  {
    photo: airpods,
    count: 1,
    name: "Airpods",
    desp:
      "",
    logo: FUTRALOVNA,
    background: ipods_konfety,

  },
  {
    photo: ipad,
    count: 1,
    name: "iPad",
    desp:
      "",
    logo: TW,
    background: ipad_konfety,

  },
  {
    photo: mince,
    count: 4,
    name: "Stříbrné mince",
    desp:
      "",
    logo: GG,
    background: mince_konfety,

},
    {
      photo: darkovy_poukaz,
      count: 90,
      name: "60 minut online konzultace s profesionálním koučem",
      desp: "Konzultace vám ukáže nové perspektivy a možnosti osobního rozvoje.",
      logo: zivot_jako_hra,
      background: darkovy_poukaz_konfety,
    },
    {
      photo: first_class,
      count: 20,
      name: "Prémiové členství v klubu First Class",
      desp: "Tištěný magazín First Class do schránky každé 2 měsíce.",
      logo: fc,
      background: first_class_konfety,
    },
    {
      photo: code_of_life,
      count: 6,
      name: "Online biohackingový kurz Optimalizace dne",
      desp: "Kurz pro zdraví, výkonnost a dlouhověkost.",
      logo: col,
      background: code_of_life_konfety,
    },
    {
      photo: satky,
      count: 10,
      name: "Betelný šátek",
      desp: "",
      logo: myjomi,
      background: satky_konfety,
    },
    {
      photo: opasek,
      count: 1,
      name: "Pánský černý kožený pásek",
      desp: "",
      logo: megapecka,
      background: opasek_konfety,
    },
    {
      photo: tshock,
      count: 10,
      name: "Tričko s potiskem dle vlastního výběru",
      desp: "Vytvořte si tričko s originálním návrhem.",
      logo: tshock_logo,
      background: tshock_konfety,
    },
    {
      photo: tricko,
      count: 10,
      name: "Tričko s logem essential college",
      desp: "Pochlubte se přátelům tričkem s logem EC.",
      logo: tshock_logo,
      background: tricko_konfety,
    },
    {
      photo: ec_ponozky,
      count: 50,
      name: "Ponožky s logem essential college",
      desp: "Velikosti: 36-38, 39-42, 43-46.",
      logo: ponozkarna_logo,
      background: ec_ponozky_konfety,
    },
    {
      photo: ponozky,
      count: 50,
      name: "Stylové barevné ponožky",
      desp: "Velikosti: 36-38, 39-42, 43-46.",
      logo: ponozkarna_logo,
      background: ponozky_konfety,
    },
    {
      photo: rekonstrukce,
      count: 200,
      name:
        "Roční přístup do online psychologického výcviku Rekonstrukce identity",
      desp: "Psychologický zážitkový výcvik v oblasti osobního rozvoje.",
      logo: uam,
      background: rekonstrukce_konfety,
    },


  
  {
    photo: kryty,
    count: 100,
    name: "Kryty na mobil ",
    desp:
      "",
    logo: FUTRALOVNA,
    background: kryty_konfety,

  },

  ];


export default function Soutez_block() {
  return (
    
      <div className="tile-cover">
        
       

        {ceny.map((price,index)=>{
          return (
            <div className={`${price.design==="on"? "tile ramecek":"tile"}`} style={{ background: ` url('${price.background}` }}  > 
              <img className="price-img" classnName src={price.photo} alt={price.name} />
              <div className="text">
              <span>{price.count }x</span><br/>
                <h1> {price.name} {price.design? <span className="design">vyhrává každý!</span> : null }</h1>
                <h2 className="animate-text"> {price.desp}</h2>
                <div className="tile-bottom">
                  <p className="animate-text sponzor">Sponzorem této ceny je</p>
                  <img className="animate-text partner-logo" src={price.logo} alt="" />
                  </div>
              </div>
              
            </div>
            
          )
        })}
        

            <h4 className="podminky">
            <a  href="https://psychologieprokazdeho.cz/wp-content/uploads/2021/06/Podminky-souteze-final.docx" >                Podmínky soutěže naleznete zde
</a>
            </h4>
            <Link className="zpet" to="/">Zpět na na hlavní stranu</Link>
</div>

    )
}
