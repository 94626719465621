import React from 'react';
import HeroThanks from './HeroThanks';
import "./Dekujeme.css"
import { Link } from "react-router-dom"
const Dekujeme = () => {
    return (
        <div className="dekujeme">
                        <HeroThanks />
            <h1>Děkujeme za vyplnění!</h1>
            <h3>Pokračuj na email a získej výhodu před ostatními!</h3>

            <Link className="zpet" to="/">Zpět na na hlavní stranu</Link>
        </div>
    );
}

export default Dekujeme;
