import React from 'react'
import telefon from "../imgs/telefon.png"
import ucitel from "../imgs/ucitel.png"
import prace from "../imgs/prace.png"
import umeni from "../imgs/umeni.png"
import "./Features.css"
import path from "../imgs/Path_1.png";

export default function Features() {
    return (
        <div className="section">
                  <img className="path" alt="cesta" src={path}></img>
        <div className="features">
            <div className="block">
                <img src={telefon} alt="telefon" />
                <p>Chceš se učit to, co opravdu upotřebíš v budoucí práci?</p>
            </div>
            <div className="block">
                <img src={umeni} alt="umeni" />
                <p>Chceš získat praxi již ve studiu?</p>
            </div>
            <div className="block">
                <img src={prace} alt="prace" />
                <p>Chceš se sebou umět pracovat?</p>
            </div>
            <div className="block">
                <img src={ucitel} alt="ucitel" />
                <p>Chceš studovat pod vedením odborníků z praxe?</p>
            </div>
            </div>
            </div>
    )
}
