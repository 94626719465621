import React from 'react'
import "./Hero.css"
import img from "../imgs/EC_LOGO.png"
import {IconContext} from "react-icons";


export default function Hero() {
    return (
        <div className="hero">
            <IconContext.Provider value={{ className:"play",  size: 42} }>
<img className="logo" src={img} alt="essential_college"/>
                <h1>#skolanamiru</h1>


            </IconContext.Provider>

        </div>
    )
}
