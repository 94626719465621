import "../App.css";
import Hero from "../Components/Hero";
import Slider from "../Components/Slider";
import Ceny from "../Components/Soutez_block"
import React from 'react'
import "./ceny.css"
export default function ceny() {
    return (
        <div>
          <Hero />
          <Ceny/>
          <Slider />            
        </div>
    )
}
