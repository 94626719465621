import "../App.css";
import Challenge from "../Components/Challenge";
import React from 'react'
import {Link} from "react-router-dom"

export default function doma() {
    return (
        <div className="homeSec">
        <Challenge />
        <div className="onasside">
               <Link to="/o-nas"><h3 className="odkazinfo">VÍCE INFORMACÍ O ŠKOLE NA MÍRU</h3></Link> 
                </div>
        </div>
    )
}
