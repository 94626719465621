import Doma from "./pages/doma";
import Ceny from "./pages/ceny";
import Onas from "./pages/Onas";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import ScrollToTop from "react-router-scroll-top"
import Dekujeme from "./Components/Dekujeme";
function App() {




  return (
    <Router>
          <ScrollToTop>
          <Switch>
          <Route exact path="/" component={Doma}></Route>
          <Route exact path="/ceny" component={Ceny}></Route>
          <Route exact path="/dekujeme" component={Dekujeme}></Route>
          <Route exact path="/o-nas" component={Onas}></Route>
          <Route path="*" component={Doma} />
        </Switch>
        </ScrollToTop>

    </Router>
  );
}

export default App;


