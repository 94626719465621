import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import col from "../imgs/loga_resize/col.png"
import ec from "../imgs/loga_resize/ec.png"
import fc from "../imgs/loga_resize/fc.png"
import iap from "../imgs/loga_resize/iap.png"
import optimal from "../imgs/loga_resize/optimal.png"
import rimoto from "../imgs/loga_resize/rimoto.png"
import tshock from "../imgs/loga_resize/tshock.png"
import uam from "../imgs/loga_resize/uam.png"
import zjh from "../imgs/loga_resize/zjh.png"
import ponozkarna from "../imgs/loga_resize/ponozkarna.png"
import GG from "../imgs/loga_resize/GG.png"
import MS from "../imgs/loga_resize/MS.png"
import TW from "../imgs/loga_resize/TW.png"
import myjomi from "../imgs/loga_resize/myjomi.png"
import futralovna from "../imgs/loga_resize/futralovna.png"

import "./Slider.css"
const handleDragStart = (e) => e.preventDefault();

const items = [
    <img src={col} alt="col" onDragStart={handleDragStart} className="pic"/>,
    <img src={ec} alt="ec" onDragStart={handleDragStart} className="pic"/>,
    <img src={iap} alt="uam_2" onDragStart={handleDragStart} className="pic"/>,
    <img src={optimal} alt="rimoto_logo" onDragStart={handleDragStart} className="pic"/>,
    <img src={rimoto} alt="optimal" onDragStart={handleDragStart} className="pic"/>,
    <img src={tshock} alt="optimal" onDragStart={handleDragStart} className="pic" />,
    <img src={fc} alt="fc" onDragStart={handleDragStart} className="pic"/>,
    <img src={uam} alt="optimal" onDragStart={handleDragStart} className="pic"/>,
    <img src={zjh} alt="optimal" onDragStart={handleDragStart} className="pic"/>,
    <img src={ponozkarna} alt="ponozkarna" onDragStart={handleDragStart} className="pic"/>,
    <img src={GG} alt="ponozkarna" onDragStart={handleDragStart} className="pic"/>,
    <img src={MS} alt="ponozkarna" onDragStart={handleDragStart} className="pic"/>,
    <img src={futralovna} alt="ponozkarna" onDragStart={handleDragStart} className="pic"/>,
    <img src={TW} alt="ponozkarna" onDragStart={handleDragStart} className="pic"/>,
    <img src={myjomi} alt="ponozkarna" onDragStart={handleDragStart} className="pic"/>,
];
const responsive = {
    0: { items: 2 },
    390: { items: 3 },

    568: { items: 3 },
    1024: { items: 6 },
};
export default function Slider() {
    return (
        <div className="slider" id="soutez">
            <div className="section">
                <AliceCarousel infinite={true} mouseTracking items={items} autoPlay autoPlayInterval="1000"  disableDotsControls={true}            responsive={responsive}
       disableButtonsControls={true}
 />
            </div>
        </div>
    )
}
