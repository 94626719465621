import React from "react";
import "./Challenge.css";
import skutr from "../imgs/skutr.png";
import kolobezka from "../imgs/kolobezka.png";
import iPad from "../imgs/iPad.png";
import airpods from "../imgs/airpods.png";
import { Link } from "react-router-dom";
import Slider from "./Slider";

export default function Challenge() {
  return (
    <div className="section">
      <div className="challange">
        <img className="vyhry kolobezka" src={kolobezka} alt="kolobezka" />
        <img className="vyhry airpods" src={airpods} alt="airpods" />
        <img className="vyhry skutr" src={skutr} alt="skutr" />
        <img className="vyhry iPad" src={iPad} alt="iPad" />
        <div className="vypln">
          <div className="left">
            <h2>VY</h2>
          </div>
          <div className="right">
            <h3>plň</h3>
            <h3>hraj</h3>
          </div>
        </div>
        <h4> 😍 Máme toho pro vás více! 😍</h4>
        <Link to="/ceny">
          {" "}
          <p className="vice">
            <span>
              <i class="fas fa-arrow-right"></i> 👏SEZNAM CEN | Vyhrává každý👏
              <i class="fas fa-arrow-left"></i>
            </span>{" "}
          </p>
        </Link>

        <h1 style={{ margin: "8rem", color: "#D41317" }}>Soutěž ukončena</h1>
        <Slider />
      </div>
    </div>
  );
}
