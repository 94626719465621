import "../App.css";
import Hero from "../Components/Hero";
import Slider from "../Components/Slider";
import React from 'react'
import Features from "../Components/Features";
import { Link } from "react-router-dom"
const Onas = () => {
    return (
        <div className="onas">
            <Hero />
            <div className="section">
                <h1>O ŠKOLE NA MÍRU</h1>
                <Features />
                <div className="popis">

                <p>U nás na essential college máme mnohaleté zkušenosti s praktickým profesním vzděláním pro obchodníky, manažery, majitele firem a spoustu dalších. A jak u nás vzděláváme? Hlavně prakticky. Zastaralé sylaby plné nudné teorie, ztuhlé akademické přístupy a dlouhé studium, které vám sice přinese titul, ale žádné zkušenosti – tak na to u nás můžete zapomenout. My své klienty učíme jen to, co opravdu upotřebí v práci, a dbáme na to, aby si nově nabyté poznatky nejenom osvojili, ale aby je taky aplikovali do praxe. Protože bez praxe nejsou koláče! A absolvovat u nás může jen ten, kdo má reálné výsledky v práci i osobním životě. Protože věříme, že jenom takové vzdělání má smysl.</p>
                <p>Škola na míru je pak projekt, v rámci kterého připravujeme pro absolventy středních škol výukový program zaměřený na mezinárodní podnikání, a jak už sám název napovídá, chceme to udělat „na míru“. My totiž víme, co vás musíme naučit, abyste byli úspěšní, ale chceme od vás slyšet jak. Jak to udělat, aby to pro vás bylo co nejpohodlnější a nejefektivnější. Aby to opravdu bylo vzdělání, které za to stojí.</p>
                <p>A k tomu taky potřebujeme vaši pomoc. Zapojte se do projektu, který změní vzdělání v České republice. Pojď s námi budovat školu, která bude VAŠE. Pojďte s námi budovat Školu na míru.</p>
                <Link className="zpet" to="/"><p>Zpět na na hlavní stranu</p></Link>
                </div>

          <Slider />  
            </div>
        </div>
    );
}

export default Onas;
